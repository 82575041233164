<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area" :style="{visibility: maxHeight > 0 ? 'visible' : 'hidden', overflow: 'hidden'}">
        <div id="pagelayout">
          <div>
            <div style="display: inline-block; width: 100%; margin-top: -1rem;" class="top-bar">
              <div id="dateRangeSelector" style="float: left;">
                <label>Selection Period:</label>&nbsp;&nbsp;<span v-if="hideDateLabel" style="font-size: .75em; color: #808080">({{ dates.currentDateRange.start | formatDate }} to {{dates.currentDateRange.end | formatDate}})</span>
                <br />
                <kendo-dropdownlist id="ddlReportDateRange" name="ddlReportDateRange"
                                    v-model="selectedReportDateRange"
                                    :data-source="reportDateRanges"
                                    :data-text-field="'text'"
                                    :data-value-field="'value'"
                                    @select="onReportDateRangeSelect">
                </kendo-dropdownlist>
              </div>

              <div style="float: left;">
                <div style="margin-left: .5rem; margin-bottom:0px!important;" id="dateRangePicker" v-show="selectedReportDateRange === '3'">
                    <div style="display: inline-flex">
                      <div style="margin-right: .5rem;">
                        <label for="reportStartDate">Start</label> <br />
                        <kendo-datepicker id="reportStartDate"
                                          name="reportStartDate"
                                          style="width: 200px"
                                          required="required"
                                          v-model="dates.currentDateRange.start"
                                          :min="dates.start.minDate"
                                          :max="dates.start.maxDate"
                                          :format="'yyyy-MM-dd'"
                                          v-on:change="onStartDateRangeChange">
                        </kendo-datepicker>
                        <br />
                        <span data-for='reportStartDate' class='k-invalid-msg'></span>
                      </div>
                      <div>
                        <label for="reportEndDate">End</label><br />
                        <kendo-datepicker id="reportEndDate"
                                          name="reportEndDate"
                                          style="width: 200px"
                                          required="required"
                                          v-model="dates.currentDateRange.end"
                                          :max="dates.end.maxDate"
                                          :format="'yyyy-MM-dd'"
                                          v-on:change="onEndDateRangeChange">
                        </kendo-datepicker>
                        <br />
                        <span data-for='reportEndDate' class='k-invalid-msg'></span>
                      </div>
                    </div>
                  </div>
              </div>

              <div style="float: right;  margin-top: .9rem">
                <a class="button is-accent" :disabled="!$hasPermissions(clientSession, ['ACTIVITY_REPORT'], 2)"
                        @click="!$hasPermissions(clientSession, ['ACTIVITY_REPORT'], 2) ? null : getCsvExport()">
                      <span class="icon">
                        <i class="fal fa-file-csv"></i>
                      </span>
                      <span>
                        Export Log
                      </span>
                    </a>
              </div>

              <div style="float: right; margin-top: .9rem; margin-right: .5rem;">
                <a class="button is-light" @click="clearFilters">
                      <span>Clear Filters</span>
                    </a>
              </div>
            </div>
            <Grid
              :style="{height: maxHeight + 'px', maxHeight: maxHeight  + 'px'}"
              :filter="filter"
              :data-items="activity"
              :sortable="true"
              :sort="sort"
              :filterable="true"
              :pageable="{
                ...pageable,
                pageSizes: pageSizes
              }"
              :page-size="pageSize"
              :skip="skip"
              :take="take"
              :total="totalRecords"
              :columns="columns"
              @headerselectionchange="onHeaderSelectionChange"
              @filterchange="filterChangeHandler"
              @sortchange="sortChangeHandler"
              @pagechange="pageChangeHandler">
              <template v-slot:dateTemplate="{props}">
                <td style="color: #424242 !important;">
                  {{ getLocalDate(props.dataItem.createdAt) }}
                </td>
              </template>
              <template v-slot:emailTemplate="{props}">
                <td>
                    {{ getEmailIfExists(props.dataItem.userId) }}
                </td>
              </template>
              <template v-slot:filterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" :id="`${props.field}`" :value="props.value" @input="(ev) => {methods.change({operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev});}">
                  </div>
                </div>
              </template>
            </Grid>
          </div>
        </div>
      </div>

      <div v-if="maxHeight === 0">
        <Loading />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { Grid } from '@progress/kendo-vue-grid'
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import moment from 'moment'
import Papa from 'papaparse'

Vue.use(DropdownsInstaller)

Vue.filter('formatDate', function (date) {
  if (date) {
    return moment(new Date(date).toISOString().substring(0, 10)).format('MMM D, YYYY')
  }
})

let pageName = 'Activity Report'

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

// https://stackoverflow.com/a/39983194
function downloadFile (fileName, urlData) {
  let aLink = document.createElement('a')
  aLink.download = fileName
  aLink.href = urlData
  aLink.setAttribute('target', '_blank')

  let event = new MouseEvent('click')
  aLink.dispatchEvent(event)
}

export default {
  components: {
    Loading,
    'Grid': Grid,
    'kendo-datepicker': DatePicker
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      dates: {
        start: {
          minDate: new Date(2014, 0, 1),
          maxDate: new Date(moment().toDate())
        },
        end: {
          minDate: new Date(moment().toDate()),
          maxDate: new Date(moment().toDate())
        },
        currentDateRange: { start: new Date(moment().year(), 0, 1), end: new Date(moment().toDate()) }
      },
      reportDateRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'Custom Date Range', value: '3' },
        { text: 'All', value: '4' }
      ],
      selectedReportDateRange: '1',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      input: {
        userId: 0,
        email: '',
        createdAt: null,
        type: ''
      },
      payload:
      {
        filter:
        {
          userId: null,
          type: null,
          dateFilter:
          {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'createdAt',
          title: 'Timestamp',
          filterable: false,
          cell: 'dateTemplate',
          filterCell: 'filterSlotTemplate',
          width: '200px'
        },
        {
          field: 'userId',
          title: 'User',
          filterable: true,
          cell: 'emailTemplate',
          filterCell: 'filterSlotTemplate',
          width: '200px'
        },
        {
          field: 'type',
          title: 'Event Type',
          filterable: true,
          filterCell: 'filterSlotTemplate',
          width: '150px'
        },
        {
          field: 'description',
          title: 'Description',
          filterable: false,
          filterCell: 'filterSlotTemplate',
          sortable: false
        }
      ],
      activity: [],
      isLoading: false,
      showFilters: false,
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageheading: pageName,
      sort: [
        { field: 'createdAt', dir: 'desc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      }
    }
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getLogs)
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    hasFilter () {
      let isFilterDefault =
        (this.input.email === '' || this.input.email === null) &&
        (this.input.startDate === '' || this.input.startDate === null) &&
        (this.input.endDate === null || this.input.endDate === '') &&
        (this.input.eventType === null || this.input.eventType === '')
      return !isFilterDefault
    },
    filterEmailAddresses () {
      if (!this.input.email || this.input.email === null) {
        return []
      }
      let input = this.input.email.toLowerCase()

      let res = this.usersArr.filter(user => {
        let emailMatch = user.email.toLowerCase().includes(input)
        let nameMatch =
          user.firstName.toLowerCase().includes(input) ||
          user.lastName.toLowerCase().includes(input)
        return emailMatch || nameMatch
      })

      return res
    },
    columns () {
      return [
        ...this.staticColumns
      ]
    },
    hideDateLabel () {
      return this.selectedReportDateRange !== '3' && this.selectedReportDateRange !== '4'
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  async created () {
    if (!this.$route.query.page) {
      this.getInitialScreen()
    } else {
      if (localStorage.activityFilters) { this.filters = JSON.parse(localStorage.getItem('activityFilters')) }

      if (localStorage.activitySort) { this.sort = JSON.parse(localStorage.getItem('activitySort')) }

      if (localStorage.activitySelection) {
        await this.loadQueryParams(JSON.parse(localStorage.getItem('activitySelection')))
      }
    }

    if (localStorage.activityPageSize) {
      let pageSize = JSON.parse(localStorage.getItem('activityPageSize'))
      this.take = pageSize
      this.perPage = pageSize
      this.pageSize = pageSize
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.top-bar',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight

        await this.getUsersLookup()
        await this.loadQueryParams(this.$route.query)
        await this.checkPage(this.getLogs)
      }

      that.getListHeights('body-content-area', 40, 79 + 43.8, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 79 + 43.8, elements, func.bind(that))
      })
    })
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    getInitialScreen: function () {
      this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.stripDate(this.getCurrentDateRange().start.toISOString()) }) })
      this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: this.stripDate(this.getCurrentDateRange().end.toISOString()) }) })
    },
    getCurrentDateRange: function () {
      const dateObj = new Date()
      const month = dateObj.getUTCMonth()
      const day = dateObj.getUTCDate()
      const year = dateObj.getUTCFullYear()
      const start = new Date(year, 0, 1)
      const end = new Date(year, month, day)

      return {
        start: start,
        end: end
      }
    },
    getLastYearDateRange: function () {
      const lastYear = (new Date().getUTCFullYear() - 1)
      const start = moment(new Date(lastYear, 0, 1))
      const end = moment(new Date(lastYear, 11, 31))

      return {
        start: start.toDate(),
        end: end.toDate()
      }
    },
    isCurrentDateRange: function () {
      if (!this.dates.currentDateRange) { return false }
      const range = this.getCurrentDateRange()
      if (!range) { return false }

      return this.dates.currentDateRange.start === range.start && this.dates.currentDateRange.end === range.end
    },
    async resetFilters () {
      this.input.startDate = ''
      this.input.endDate = ''
      this.input.email = ''
      this.input.eventType = ''
      this.$router.push({ query: { page: 1 } }).catch(err => { this.handleApiErr(err) })
      await this.checkPage(this.getLogs)
    },
    getLocalDate (utcDate) {
      return moment(utcDate).local().format('YYYY-MM-DD hh:mm:ss A')
    },
    getEmailIfExists (userId) {
      if (this.users) {
        let user = this.users.get(userId)

        if (user && user.email) {
          return user.email
        } else if (user && parseInt(user.id) !== 0) {
          return user.id
        } else {
          return 'User Removed'
        }
      }
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z to 2016-02-24
      // Note this is needed to prevent off-by-one date errors due to the timestamp and timezone offset
      return dateString.substring(0, 10)
    },
    loadQueryParams (query) {
      if (query.selectedReportDateRange) {
        this.selectedReportDateRange = query.selectedReportDateRange
        // Reset paging when querystring values present since individual page contents cannot be guaranteed
        this.page = 1
        this.perPage = 20
      }

      if (query.dates && query.dates.start) { this.dates.currentDateRange.start = new Date(query.dates.start).toISOString() }

      if (query.dates && query.dates.end) { this.dates.currentDateRange.end = new Date(query.dates.end).toISOString() }

      return false
    },
    getSearchQuery () {
      // set default sort
      this.payload.orderBy = 'CreatedAt DESC'
      this.payload.limit = this.take || 20
      this.payload.offset = this.skip || 0

      this.payload.filter.dateFilter.startDate = new Date(this.dates.currentDateRange.start)
      this.payload.filter.dateFilter.endDate = new Date(this.dates.currentDateRange.end)

      if (this.filters) { this.payload.filter.gridFilters = this.filters } else { this.payload.filter.gridFilters = [] }

      if (this.sort.length) { this.payload.sort = this.sort[0].field + ' ' + this.sort[0].dir }
    },
    async getUsersLookup () {
      // TODO: dedupe
      try {
        let route = `/users`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.users = new Map(response.data.records.map(i => [i.id, i]))
        this.usersArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async applyFilters () {
      this.goToPage(1, this.getLogs)
    },
    async getLogs (isInitialLoad = true) {
      try {
        this.isLoading = true

        let response = {}

        let route = `/audit-logs/search/`
        await this.getSearchQuery()
        response = await this.axios.post(route, this.payload)

        this.totalRecords = response.data.page.totalRecords
        this.page = response.data.page.page
        this.activity = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getCsvExport () {
      let route = `/audit-logs/export/`
      let response = await this.axios.post(route, this.payload)
      let csv = Papa.unparse(response.data, { skipEmptyLines: true })
      downloadFile('activitylog.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv))
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'users', this.users.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.users
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      localStorage.setItem('activityPageSize', event.page.take)
      this.getLogs(false)
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, { [key]: value })
      this.pageable = newPageableState
    },
    changeHandler: function (type, value) {
      this.updatePagerState(type, value)
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('activitySort', JSON.stringify(this.sort))
      this.getLogs(false)
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach(element => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') { this.filters = this.filters.filter(x => x.field !== e.event.field) }
      }

      localStorage.setItem('activityFilters', JSON.stringify(this.filters))

      this.skip = 0
      this.getLogs(false)
    }, 500),
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [
        { field: 'CreatedAt', dir: 'desc' }
      ]

      localStorage.removeItem('activityFilters')
      localStorage.removeItem('activitySort')

      this.getLogs(false)
    },
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    },
    setCurrentDateRange: function () {
      try {
        const range = this.getCurrentDateRange()
        if (range) {
          this.dates.maxDate = range.end
          this.dates.currentDateRange = range
        }
      } catch (e) {
        this.handleApiErr(e)
      }
    },
    onStartDateRangeChange: function (e) {
      this.dates.currentDateRange.start = new Date(e.sender.value())

      if (this.dates.currentDateRange.start && this.dates.currentDateRange.end) {
        if (moment(this.dates.currentDateRange.start).isAfter(this.dates.currentDateRange.end)) {
          // TODO WL add visual cues
          return false
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates.currentDateRange
        }
        localStorage.setItem('activitySelection', JSON.stringify(queryParams))

        this.getLogs(false)
        this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.stripDate(this.dates.currentDateRange.start.toISOString()) }) })

        return false
      }
    },
    onEndDateRangeChange: function (e) {
      this.dates.currentDateRange.end = new Date(e.sender.value())

      if (this.dates.currentDateRange.start && this.dates.currentDateRange.end) {
        if (moment(this.dates.currentDateRange.end).isBefore(this.dates.currentDateRange.start)) {
          // TODO WL add visual cues
          return false
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates.currentDateRange
        }
        localStorage.setItem('activitySelection', JSON.stringify(queryParams))

        this.getLogs(false)
        this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: this.stripDate(this.dates.currentDateRange.end.toISOString()) }) })
        return false
      }
    },
    onReportDateRangeSelect: async function (e) {
      const selectedOptionValue = e.dataItem.value
      this.payload.filter.allReports = false
      this.skip = 0
      try {
        this.selectedReportDateRange = selectedOptionValue
        let range = {}

        if (selectedOptionValue === '3' || selectedOptionValue === '4') {
          this.dates.currentDateRange.start = null
          this.dates.currentDateRange.end = null
          if (selectedOptionValue === '4') {
            this.payload.filter.allReports = true
            this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: undefined }) })
            this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: undefined }) })
            await this.getLogs()
          }
        } else {
          if (selectedOptionValue === '1') { range = this.getCurrentDateRange() }
          if (selectedOptionValue === '2') { range = this.getLastYearDateRange() }

          if (range) {
            this.dates.currentDateRange = range
            this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.stripDate(range.start.toISOString()) }) })
            this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: this.stripDate(range.end.toISOString()) }) })
            await this.getLogs()
          }
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates
        }

        localStorage.setItem('activitySelection', JSON.stringify(queryParams))

        this.$router.push({ query: Object.assign({}, this.$route.query, { selectedReportDateRange: selectedOptionValue }) })
      } catch (e) {
        this.handleApiErr(e)
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-grid td:first-child {
  color: #424242!important;
}

.k-grid th:first-child {
  color: #424242!important;
}

.k-dropdown {
  width: auto!important;
  min-width: 200px;
}

.k-master-row > td:first-of-type {
  color: black !important;
}
</style>
